// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-estore-js": () => import("./../../../src/pages/estore.js" /* webpackChunkName: "component---src-pages-estore-js" */),
  "component---src-pages-games-js": () => import("./../../../src/pages/games.js" /* webpackChunkName: "component---src-pages-games-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-nintendoswitch-js": () => import("./../../../src/pages/nintendoswitch.js" /* webpackChunkName: "component---src-pages-nintendoswitch-js" */),
  "component---src-pages-outofstock-js": () => import("./../../../src/pages/outofstock.js" /* webpackChunkName: "component---src-pages-outofstock-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */)
}

